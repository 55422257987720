/** @type {{ defaultLocale: 'en', locales: string[] }} */ "use strict";
var i18n = {
    defaultLocale: "en",
    locales: [
        "en",
        "en-US",
        "en-AU",
        "en-GB",
        "en-CA",
        "en-NZ",
        "en-IN",
        "en-HK",
        "en-SG",
        "en-IE",
        "en-DE",
        "en-NL",
        "en-SE",
        "en-NO",
        "en-FR",
        "en-CH",
        "en-BE",
        "en-AT",
        "en-ES",
        "en-DK",
        "en-FI",
        "en-IT",
        "en-JP",
        "en-IL",
        "en-KR",
        "en-PT",
        "en-BR",
        "en-CZ",
        "en-PL",
        "en-PH",
        "de",
        "de-DE",
        "de-CH",
        "de-AT",
        "nl",
        "nl-NL",
        "nl-BE",
        "sv",
        "fr",
        "fr-FR",
        "fr-CA",
        "fr-CH",
        "fr-BE",
        "no",
        "es",
        "es-ES",
        "da",
        "pt",
        "pt-PT",
        "pt-BR",
        "fi",
        "cs",
        "pl",
        "ja",
        "it",
        "he",
        "ko", 
    ],
    domains: [
        {
            domain: process.env.NEXT_PUBLIC_BASE_URL === "http://localhost:3000" ? "dev.don-efficace.fr" : "staging.don-efficace.fr",
            locales: [
                "fr-FR"
            ],
            defaultLocale: "fr-FR"
        }, 
    ]
};
/** @type {string[]} */ var PLEDGE_LOCALES = [
    "en",
    "nl",
    "es",
    "de",
    "fr",
    "pt",
    "pt-BR",
    "ja"
];
/** @type {Object.<string, string>} */ var localeNames = {
    en: "English",
    "en-US": "English (US)",
    "en-AU": "English (AU)",
    "en-GB": "English (GB)",
    cs: "Čeština - Czech",
    da: "Dansk - Danish",
    de: "Deutsch - German",
    es: "Espa\xf1ol - Spanish",
    fi: "Suomi - Finnish",
    fr: "Fran\xe7ais - French",
    he: "עברית - Hebrew",
    it: "Italiano - Italian",
    ja: "日本語 - Japanese",
    ko: "한국어 - Korean",
    nl: "Nederlands - Dutch",
    no: "Norsk - Norwegian",
    pl: "Polski - Polish",
    pt: "Portugu\xeas - Portuguese",
    "pt-BR": "Portugu\xeas (BR) - Portuguese (Brazilian)",
    sv: "Svenska - Swedish"
};
// EN doesn't have a default country, because we might want to show country spe
/** @type {Object.<string, string>} */ var defaultLanguageCountries = {
    cs: "CZ",
    da: "DK",
    de: "DE",
    es: "ES",
    fi: "FI",
    fr: "FR",
    he: "IL",
    it: "IT",
    ja: "JP",
    ko: "KR",
    nl: "NL",
    no: "NO",
    pl: "PL",
    pt: "PT",
    sv: "SE"
};
/** @type {string} */ var languageCookieName = "LOCALE_LANGUAGE";
/** @type {string} */ var countryCookieName = "LOCALE_COUNTRY";
/** @type {string[]} */ var EN_ONLY_PATHS = [
    // These only support 'en' for now
    "404",
    "about-us",
    "blog",
    "dashboard",
    "donate",
    "donor-lottery",
    "faq",
    "fundraisers",
    "giving-season",
    "people",
    "search", 
];
module.exports = {
    i18n: i18n,
    PLEDGE_LOCALES: PLEDGE_LOCALES,
    localeNames: localeNames,
    defaultLanguageCountries: defaultLanguageCountries,
    languageCookieName: languageCookieName,
    countryCookieName: countryCookieName,
    EN_ONLY_PATHS: EN_ONLY_PATHS
};
